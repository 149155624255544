
.tour-radar-suggestions-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.tour-radar-suggestions-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.tour-radar-suggestions-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.tour-radar-suggestions-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.tour-radar-suggestions-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 280px);
    overflow: auto;
}
.tour-radar-suggestions-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.tour-radar-suggestions-list-item-header-image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tour-radar-suggestions-list-item-header-image {
    max-height: 50px;
}
.tour-radar-suggestions-list-item-header-text {
    flex: 1;
    text-align: right;
    font-weight: 600;
}
.tour-radar-suggestions-list-item-image-holder {
}
.tour-radar-suggestions-list-item-image-holder > img {
    max-width: 318px;
}
.tour-radar-suggestions-list-item-price-holder {

}
.tour-radar-suggestions-list-item-time-reviews-holder {
    display: flex;
}
