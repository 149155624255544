
.page-content-background-rectangle {

}

/*-----------Content Area--------------*/
.area-travel-made-easy {
    position: relative;
    display: flex;
    width: 80%;
    margin: 50px auto;
    border-left: 3px dotted orange;
}
.area-travel-made-easy-badge-holder {
    position: absolute;
    top: 0;
    left: 0;
}
.area-travel-made-easy-left-container {
    min-width: 30%;
    margin-top: 125px;
    vertical-align: middle;
    position: absolute;
}
.area-travel-made-easy-right-container {
    flex: 1;
    height: auto;
    text-align: right;
}
.ET-holder {
    height: 800px;
}
.ET-holder img {
    height: 800px;
    border: 3px dotted #f09637;
}
.content-header {
    color: #081361;
    font-size: 40px;
    text-align: center;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}
.content-header-banner-title-holder {
    font-size: 80px;
    font-family: "Brush Script MT", cursive;
}
.content-header-banner-subtitle-holder {
    font-size: 35px;
}
.content-tagline {
    color: #081361;
    font-size: 18px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: lighter;
}
.get-started-holder {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}
.get-started-button {
    width: 200px;
    height: 50px;
    background-color: #abd154;
    border: 2px solid #abd154;
    color: #081361;
    font-family: sans-serif;
    font-size: 18px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.get-started-button:hover {
    background-color: #081361;
    border: 2px solid #081361;
    color: #e8f2ed;
}
/*-----------AI Area--------------*/
.area-ai-powered{
    width: 95%;
    margin-top: 200px;
    margin-bottom: 250px;
}
.ai-statement {
    text-align: center;
    font-weight: lighter;
    font-size: 44px;
    color: #081361;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: normal; /* Adjust as needed */
    animation:
            typing 3.5s steps(40, end),
            blink-caret .75s step-end infinite;
}
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
}

/*-----------Video Area--------------*/
.area-promo-video {
    display: flex;
    width: 95%;
    margin: 0 auto 250px;
    text-align: center;
}
.promo-video-holder {
    flex: 60%;
    margin-top: 20px;
}
.promo-video {
     text-align: center;
     margin-top: 50px;
 }
.promo-video iframe {
    border: 4px dotted #abd154;
}
.promo-video-content {
    flex: 40%;
    margin-top: 20px;
    padding: 0 10px;
    text-align: center;
}
.promo-video-header {
    color: #081361;
    font-size: 46px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.promo-video-subheader {
    color: #f09637;
    font-size: 26px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-weight: lighter;
}

.area-features {
    width: 95%;
    margin: 0 auto;
}
.features-header-holder {
    width: 40%;
    float: right;
    margin-top: 20px;
}
.features-header {
    color: #081361;
    font-size: 46px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.features-subheader {
    color: #f09637;
    font-size: 26px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-weight: lighter;
}
.bottom-features {
    width: 100%;
}
.features-container {
    margin-top: 200px;
    margin-bottom: 200px;
}
.features-features {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.features-title {
    font-size: 46px;
    color: #081359;
    margin: 10px;
}
.features-list {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}
.features-row1,
.features-row2 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
}
.features-column1,
.features-column-2,
.features-column-3 {
    display: grid;
}
.features-box1-column1,
.features-box2-column1,
.features-box1-column2,
.features-box2-column2,
.features-box1-column3,
.features-box2-column3 {
    width: 300px;
    height: 300px;
    margin-right: 30px;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    box-shadow: 0 5px 10px -6px #777;
}
.features-box1-column3,
.features-box2-column3 {
    margin-right: 0px;
}
.box-1,
.box-2 {
    width: 300px;
}
.todos-image,
.ideas-image,
.itinerary-image,
.map-image {
    margin-left: auto;
    margin-right: auto;
}
.todos-image {
    width: 70%;
    margin-top: 30px;
}
.ideas-image {
    width: 80%;
    margin-top: 10px;
}
.itinerary-image {
    width: 60%;
    margin-top: 30px;
}
.map-image {
    width: 55%;
    margin-top: 10px;
}
.itinerary-box-title,
.map-box-title,
.features-box-title,
.supplies-box-title,
.collaboration-box-title {
    font-size: 22px;
    text-align: center;
    color: #10318f;
}
.itinerary-box-title {
    margin-top: 55px;
}
.map-box-title {
    margin-top: 20px;
}
.features-box-title {
    margin-top: 20px;
}
.supplies-box-title {
    margin-top: 5px;
}
.collaboration-box-title {
    margin-top: 40px;
}
.features-box-subtitle {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    color: darkgrey;
    margin-left: 10px;
    margin-right: 10px;
}
.reviews-container {
    margin-top: 200px;
    border-right: 3px dotted orange;
    padding-right: 50px;
    padding-bottom: 25px;
}
.reviews-header {
    color: #081361;
    font-size: 46px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.testimonials-area {
    margin-bottom: 100px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
}
.testimonials-row {
    display: flex;
}
.testimonials-column1,
.testimonials-column2,
.testimonials-column3 {
    border-radius: 15px;
    height: 350px;
    width: 300px;
    margin-right: 30px;
    box-shadow: 0 5px 10px -6px #777;
    position: relative;
}
.testimonials-column1 {
    border: 1px solid #f09637;
    background-color: #fef4ec;
}
.testimonials-column2 {
    border: 2px solid #abd154;
    background: #e8f2ed;
}
.testimonials-column3 {
    border: 3px solid #f8f1ae;
    background-color: #fcf9dd;
}
.testimonials-title {
    font-size: 24px;
    color: #081359;
    margin: 10px;
}
.testimonials-label {
    color: #081359;
    margin: 10px;
}
.testimonials-label1 {
    color: #081359;
    margin: 15px;
}
.testimonials-sublabel {
    color: #081359;
    position: absolute;
    bottom: 0;
    margin-left: 30px;
    margin-bottom: 20px;
}

/*-----------featured-in Area--------------*/

.area-featured-in,
.area-helps-in-success {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.area-helps-in-success {
    margin-top: 75px;
}
.featured-in-title,
.success-with-title {
    font-size: 20px;
}
.featured-in-title-color-box {
    width: 125px;
    height: 25px;
    background-color: #e8f2ed;
    margin-top: -20px;
    margin-left: 25px;
}
.success-with-title-color-box {
    width: 250px;
    height: 25px;
    background-color: #cbe0f7;
    margin-top: -20px;
    margin-left: 25px;
}
.featured-in-icon-sets {
    display: flex;
}
.featured-in-icon {
    width: 150px;
}
.featured-in-icon img {
    height: 70px;
}
.featured-in-icon2 img,
.success-with-icon-sets img {
    height: 60px;
    margin-right: 50px;
}
.success-with {
    margin-top: 50px;
}
.featured-in-title-holder,
.success-with-title-holder {
    margin-bottom: 25px;
}
.success-with-icon-sets {
    display: flex;
}
.partnered-with-icon {
    width: 220px;
}
.success-with-icon-generator {
    margin-right: 40px;
}
.success-with-icon-generator img {
    height: 50px;
}

/*-----------Travel Agents Area--------------*/
.travelAgents-features {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}
.travelAgents-title {
    font-size: 46px;
    color: #081359;
    margin: 10px;
    text-align: center;
}
.travelAgents-subtitle {
    color: #081361;
    font-size: 18px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    font-weight: lighter;
}

/*-----------PA App Area--------------*/

.area-pa-app-container {
    display: flex;
    height: 400px;
    background-color: #e8f2ed;
    margin-top: 350px;
    margin-bottom: 200px;
}
.area-pa-app-text-holder-left,
.area-pa-app-text-holder-right {
    flex: 1;
}
.area-pa-app-text-holder-left {
    padding-top: 40px;
}

.area-pa-app-title {
    font-size: 34px;
    color: #10318f;
}
.area-pa-app-subtitle {
    color: grey;
    margin-top: 20px;
    font-size: 20px;
    font-weight: lighter;
}
.banner-get-started {
    width: 40%;
    margin-top: 50px;
}
.banner-get-started-holder {
    width: 200px;
}
.banner-get-started-button {
    width: 200px;
    height: 50px;
    background-color: #10318f;
    border: 2px solid #10318f;
    color: #ffffff;
    font-family: san-serif;
    font-size: 18px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.area-pa-app-holder-right {
    width: 40%;
    float: left;
    margin-left: 500px;
    margin-top: -200px;
    overflow: hidden;
    height: 600px;
}
.experience-images-holder {
    width: 50%;
}
.experience-image,
.experience-image2 {
    position: relative;
}
.experience-image img {
    position: absolute;
    margin-top: 75px;
}
.experience-image2 img {
    position: absolute;
    margin-left: 150px;
    margin-top: 175px;
}
.destinations-container {
    width: 100%;
    height: 500px;
    background-color: #081361;
}
.destinations-title {
    font-size: 40px;
    text-align: center;
    margin-top: 300px;
    margin-bottom: 100px;
    color: #10318f;
}
.destinations-images-holder {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}
.destination-image {
    width: 20%;
}
.destination-image-mobile {
    display: none;
}
.destination-image img {
    height: 400px;
    border: 2px dotted #fef4ec;
    border-radius: 10px;
    margin-top: -75px;
}
.destination-get-started {
    width: 100%;
}
.destination-get-started-holder {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
}
.destination-get-started-button {
    width: 300px;
    height: 50px;
    background-color: #f09637;
    border: 2px solid #10318f;
    font-size: 24px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    color: #10318f;
}
/*-----------Newsletter-----------------------------------*/
.section-newsletter-signup-content-holder {
    padding: 54px 0;
}

.section-newsletter-signup-content-holder-container {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    position: relative;
    display: flex;
}

.section-newsletter-content-holder-left {
    flex: 1;
    display: grid;
    place-content: center;
    padding-bottom: 12px!important;
    margin-bottom: 25px!important;
    margin-right: 3%;
    font-size: 18px;
}

.section-newsletter-content-holder-left-title-line {
    font-weight: 500;
    font-size: 38px;
}

.section-newsletter-content-holder-left-content-line {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font-weight: lighter;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
}

.section-newsletter-content-holder-right {
    flex: 1;
    padding: 0 20px;
}

.section-newsletter-signup-content-holder {
    background-color: #fcfcfc;
}
.section-newsletter-content-holder-left {

}
.section-newsletter-content-holder-left-content-preferences {
    width: 360px;
    margin: 0 auto;
}
.section-newsletter-content-holder-left-content-preference-item-holder {
    display: flex;
    margin: 10px;
}
.section-newsletter-content-holder-left-content-preference-item-checkbox {
    flex: 0 0;
}
.section-newsletter-content-holder-left-content-preference-item-title {
    flex: 1 0;
    margin-left: 20px;
}
.section-newsletter-content-holder-right-title-line {
    margin-bottom: 40px;
    text-align: center;
}
.section-newsletter-content-holder-right-input {
    margin: 20px;
}
.newsletter-signup-opt-in-checkbox-line-holder {
    display: flex;
    margin: 20px;
}
.newsletter-signup-opt-in-checkbox-holder {
    padding: 10px;
}
.newsletter-signup-opt-in-checkbox-holder.error {
    background-color: #ff0000;
}
.newsletter-signup-opt-in-checkbox {
    flex: 0 0;
}
.newsletter-signup-opt-in-title {
    flex: 1 0;
    margin-left: 20px;
}
.newsletter-signup-submit-button-holder {
    text-align: right;
    overflow: auto;
}
.newsletter-signup-submit-button {
    display: flex;
    float: right;
    padding: 10px 40px 10px 40px;
    color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 2px 4px var(--primary-third-color);
    border: none;
    background-color: green;
}
.newsletter-signup-submit-button-icon-check {
    font-size: 11px;
    color: #FFFFFF;
    display: none;
    margin-left: 10px;
}
.newsletter-signup-submit-button-icon-check.show {
    display: block;
}
.newsletter-signup-submit-results-holder {
    clear: both;
    margin: 20px 40px 10px 40px;
}
.footer-parent {
    background-color: #F5F4F3;
    height: 100px;
    width: 100%;
    bottom: 0;
}
.copyright {
    font-size: 10px;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: grey;
}
.footer-social-icons-holder {
    display: flex;
}
.footer-instagram-icon {
    margin-left: 20px;
}
.footer-instagram-icon,
.footer-linkedin-icon,
.footer-facebook-icon {
    margin-top: 10px;
    width: 45px;
    height: 45px;
    background-color: #cbe0f7;
    border-radius: 10px;
}
.footer-instagram-icon img,
.footer-linkedin-icon img,
.footer-facebook-icon img {
    height: 29px;
    margin-top: 7px;
}
.footer-linkedin-icon,
.footer-facebook-icon {
    margin-left: 10px;
}
.footer-linkedin-icon img {
    margin-left: 5px;
}
.footer-right-side {
    width: 15%;
    float: right;
    margin-top: 25px;
    margin-right: 100px;
}
.footer-links a {
    text-decoration: none;
    color: grey;
    font-size: 14px;
    margin: 14px;
    float: left;
    font-weight: lighter;
}
.footer-left-side {
    width: 15%;
    float: left;
}
/*----------------------------------------------*/
@media only screen and (min-width : 150px) and (max-width : 769px) {
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0px;
        padding: 10px;
        text-align: left;
        background-color: #e8f2ed;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items>ul>li>a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0px;
    }
    .header-facebook-icon img {
        margin-right: 0px;
    }
    .area-travel-made-easy {
        width: 95%;
        height: 650px;
        border-left: 5px dotted orange;
    }
    .area-travel-made-easy-badge-holder {
        margin-left: -10px;
    }
    .area-travel-made-easy-left-container {
        width: 95%;
        margin-top: 50px;
    }
    .ET-holder img {
        display: none;
    }
    .content-header  {
        width: auto;
        margin-top: 75px;
    }
    .content-header-banner-title-holder {
        font-size: 64px;
    }
    .content-header-banner-subtitle-holder {
        font-size: 25px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .second-page-area-travel-made-easy {
        width: 95%;
        margin-top: 50px;
    }
    .ai-statement-holder {
        width: 100%;
        margin-bottom: 150px;
    }
    .ai-statement {
        font-size: 20px;
    }
    .features-container {
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .features-header-holder {
        width: auto;
    }
    .features-subheader {
        margin-bottom: 25px;
    }
    .promo-video iframe {
        width: 100%;
    }
    .features-features {
        width: auto;
    }
    .features-list {
        width: 300px;
        margin-top: 50px;
    }
    .features-row1,
    .features-row2 {
        display: block;
    }
    .features-row1 {
        margin-bottom: 0px;
    }
    .features-box1-column1,
    .features-box2-column1,
    .features-box1-column2,
    .features-box2-column2,
    .features-box1-column3,
    .features-box2-column3 {
        margin-bottom: 25px;
        margin-right: 0px;
    }
    .reviews-container {
        padding-right: 0px;
    }
    .testimonials-area {
        justify-content: unset;
        overflow: auto;
        padding-bottom: 25px;
        width: 100%;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .business-icons {
        width: 95%;
        margin-top: 150px
    }
    .success-with-icon-sets {
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .featured-in-icon2 img,
    .success-with-icon-sets img {
        height: 50px;
    }
    .area-pa-app-container {
        margin-top: 250px;
    }
    .area-pa-app-text-holder-left {
        width: 40%;
        float: left;
        margin-left: 10px;
        padding-top: 30px;
    }
    .area-pa-app-title {
        font-size: 22px;
    }
    .area-pa-app-subtitle {
        font-size: 16px;
    }
    .banner-get-started {
        width: auto;
        margin-top: 40px;
    }
    .banner-get-started-holder {
        width: 160px;
    }
    .banner-get-started-button {
        width: 160px;
        font-size: 16px;
    }
    .area-pa-app-holder-right {
        width: 50%;
        margin-left: 25px;
        margin-top: -100px;
        height: 500px;
    }
    .experience-image img {
        width: 175px;
    }
    .experience-image2 img {
        width: 175px;
        margin-left: 75px;
    }
    .destinations-title {
        margin-top: 0px;
    }
    .destination-image {
        display: none;
    }
    .destination-image-mobile {
        display: flex;
    }
    .destinations-images-holder  {
        margin-left: 0px;
        margin-right: 0px;
        justify-content: initial;
    }
    .destination-image-mobile {
        width: 25%;
    }
    .destination-image-mobile img {
        height: 400px;
        border: 2px dotted #fef4ec;
        border-radius: 10px;
        margin-top: -75px;
    }
    .section-newsletter-signup-content-holder-container {
        display: block;
    }
    .section-newsletter-content-holder-left {
        width: 100%;
    }
    .footer-right-side {
        width: auto;
        margin-right: 5px;
        display: flex;
        margin-top: 10px;
    }
    .copyright {
        margin-top: 10px;
        margin-left: 5px;
        width: 125px;
    }
    .footer-social-icons-holder {
        width: 175px;
    }
    .footer-instagram-icon {
        margin-left: 10px;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width : 770px) and (max-width : 1099px) {

    /*-----------Header-------------*/
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #cbe0f7;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0px;
        padding: 10px;
        text-align: left;
        background-color: #e8f2ed;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items>ul>li>a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0px;
    }
    .header-facebook-icon img {
        margin-right: 0px;
    }
    .area-travel-made-easy {
        width: 95%;
    }
    .area-travel-made-easy-badge-holder {
        margin-left: -20px;
    }
    .content-header {
        width: 350px;
        margin-top: 10px;
    }
    .content-header-banner-title-holder {
        font-size: 56px;
    }
    .content-header-banner-subtitle-holder {
        font-size: 20px;
    }
    .area-travel-made-easy-left-container {
        float: left;
        margin-top: 100px;
        width: 50%;
    }
    .area-travel-made-easy-right-container {
        width: auto;
    }
    .content-tagline {
        font-size: 20px;
        width: 80%;
        margin-top: 30px;
        margin-bottom: 25px;
    }
    .ET-holder {
        height: 600px;
    }
    .ET-holder img {
        height: 600px;
    }
    .ai-statement-holder {
        width: 100%;
        margin-bottom: 150px;
    }
    .ai-statement {
        font-size: 22px;
        font-weight: normal;
    }
    .second-page-area-travel-made-easy {
        width: 70%;
        margin-top: 150px;
    }
    .features-header-holder {
        width: auto;
        float: none;
    }
    .features-subheader {
        margin-bottom: 50px;
        width: 450px;
    }
    .features-area {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
    }
    .features-container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 150px;
    }
    .features-features {
        width: auto;
    }
    .features-list {
        float: left;
    }
    .features-row1 {
        display: grid;
        width: 50%;
        float: left;
    }
    .features-row2 {
        display: grid;
        width: 10%;
        float: right;
    }
    .features-box1-column1,
    .features-box2-column1,
    .features-box1-column2,
    .features-box2-column2,
    .features-box1-column3,
    .features-box2-column3 {
        margin-bottom: 50px;
    }
    .reviews-container {
        padding-right: 0px;
        margin-top: 50px;
    }
    .testimonials-area {
        width: 100%;
        height: 750px;
    }
    .testimonials-row {
        display: flex;
        flex-direction: column;
    }
    .testimonials-column1,
    .testimonials-column2,
    .testimonials-column3 {
        width: 95%;
    }
    .testimonials-column2,
    .testimonials-column3 {
        margin-top: 50px;
    }
    .testimonials-sublabel {
        margin-bottom: 5px;
    }
    .business-icons {
        width: 95%;
    }
    .success-with-icon-sets {
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .featured-in-icon2 img,
    .success-with-icon-sets img {
        height: 50px;
    }
    .area-pa-app-text-holder-left {
        width: 50%;
        margin-left: 50px;
        padding-left: 20px;
    }
    .area-pa-app-holder-right {
        margin-left: 25px;
        margin-top: -100px;
        height: 500px;
    }
    .experience-image img {
        width: 200px;
    }
    .experience-image2 img {
        width: 200px;
        margin-left: 100px;
    }
    .destinations-container {
        height: 400px;
        width: 100%;
    }
    .destinations-title {
        margin-top: 0px;
    }
    .destinations-images-holder {
        width: 100%;
    }
    .destination-image {
        width: 25%;
    }
    .destination-image img {
        height: 270px;
    }
    .section-newsletter-signup-content-holder-container {
        display: block;
    }
    .section-newsletter-content-holder-left {
        width: 100%;
    }
    .copyright {
        width: 125px;
    }
    .footer-right-side {
        width: 40%;
        float: right;
        margin-right: 10px;
    }
}
/*-----------Tablet-----------------------------------*/
@media only screen and (min-width: 1100px) and (max-width: 1299px) {
    /*-----------Content Area-------------*/
    .navbar {
        font-size: 14px;
    }
    .top-menu-items-holder {
        right: 0px;
    }
    .top-menu-signup-button {
        width: 100px;
    }
    .top-menu-login-button {
        width: 120px;
    }
    .area-travel-made-easy {
        width: 95%;
    }
    .area-travel-made-easy-badge-holder {
        margin-left: -25px;
    }
    .content-header {
        padding-left: 25px;
        margin-top: 0px;
    }
    .content-header-banner-title-holder {
        font-size: 78px;
    }
    .content-header-banner-subtitle-holder {
        font-size: 28px;
    }
    .content-tagline {
        padding-left: 25px;
    }
    .area-travel-made-easy-left-container {
        margin-top: 175px;
        width: auto;
    }
    .area-travel-made-easy-right-container {
        width: auto;
        margin-right: 50px;
    }
    .second-page-area-travel-made-easy {
        width: 95%;
    }
    .ai-statement-holder {

    }
    .ai-statement {
        width: 100%;
        font-size: 39px;
    }
    .reviews-container {
        padding-right: 0px;
    }
    .success-with-icon-sets {
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .featured-in-icon2 img,
    .success-with-icon-sets img {
        height: 50px;
    }
    .area-pa-app-text-holder-left {
        width: 40%;
        margin-left: 100px;
    }
    .area-pa-app-holder-right {
        margin-left: 75px;
    }
    .destinations-images-holder {
        width: 95%;
    }
    .destination-image {
        width: 25%;
        margin-right: 10px;
    }
    .destination-image img {
        height: 350px;
    }
    .destination-get-started-holder {
        width: 325px;
    }
    .destination-get-started-button {
        width: 325px;
    }
    .footer-right-side {
        width: 25%;
        margin-right: 0px;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width: 1300px) and (max-width: 1699px) {

    .area-travel-made-easy {
        width: 80%;
    }
    .area-travel-made-easy-badge-holder {
        margin-left: -50px;
    }
    .area-travel-made-easy-left-container {
        margin-top: 75px;
        width: 45%;
    }
    .area-travel-made-easy-right-container {
        width: 45%;
    }
    .ai-statement-holder {

    }
    .ai-statement {
        width: 100%;
        font-size: 39px;
    }
    .content-header {
        margin-top: 100px;
    }
    .second-page-area-travel-made-easy {
        width: 80%;
    }
    .features-header-holder {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
    }
    .features-subheader {
        width: 80%;
    }
    .reviews-container {
        padding-right: 0px;
    }
    .success-with-icon-sets {
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .area-pa-app-text-holder-left {
        width: 30%;
        margin-left: 200px;
    }
    .area-pa-app-holder-right {
        margin-left: 100px;
    }
    .destinations-images-holder {
        width: 95%;
    }
    .destination-image {
        width: 25%;
    }
    .destination-image img {
        margin-top: -65px;
    }
    .footer-right-side {
        margin-right: 75px;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width: 1700px) and (max-width: 2199px) {

    .area-travel-made-easy {
        width: 70%;
        margin-top: 70px;
    }
    .area-travel-made-easy-left-container {
        width: 40%;
        padding-left: 80px;
    }
    .ai-statement-holder {
        width: 100%;
    }
    .ai-statement {
        font-size: 39px;
    }
    .success-with-icon-sets {
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .banner-get-started {
        width: 60%;
        margin-top: 20px;
    }
    .area-pa-app-text-holder-left {
        margin-left: 300px;
    }
    .area-pa-app-holder-right {
        margin-left: 300px;
    }
    .destinations-images-holder {
        width: 100%;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width: 2200px) and (max-width: 2500px) {
    .ai-statement-holder {
        width: 90%;
    }
    .area-pa-app-holder-right {
        margin-left: 350px;
    }
}
