
.ws-connection {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 6px !important;
    height: 6px !important;
    border-radius: 6px;
    display: block;
    opacity: 1.0;
    float: left;
}

.logo-holder {
    cursor: pointer;
}
.logo-large {
    display: block;
    height: 50px;
    margin: 10px;
}
.logo {
    height: 50px;
    margin: 10px;
}

.menu-item-selected {
    text-decoration: underline !important;
}
.menu-item-not-selected {
    text-decoration: none !important;
}
.underline {
    display: inline;
    position: relative;
    overflow: hidden;
}
.underline:after {
    content: "";
    position: absolute;
    right: 0;
    width: 0;
    bottom: 10px;
    background: #abd154;
    height: 3px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
    left: 0;
    right: auto;
    width: 100%;
}
.show-underline {
    display: inline !important;
    position: relative !important;
    overflow: hidden !important;
}
.show-underline:after {
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 10px;
    background: #abd154;
    height: 3px;
}


.duplicate-row {
    background-color: rgb(253, 237, 237) !important; /* Light red background for duplicates */
}