

.content-area-features {
    width: 100%;
    min-height: 100%;
    padding-top: 90px;
    background-color: #FFFFFF;
    overflow: auto;
}

.section1-content-holder,
.section1-2-content-holder,
.section1-3-content-holder,
.section2-content-holder,
.section3-content-holder,
.section4-content-holder,
.section5-content-holder,
.section6-content-holder,
.section7-content-holder,
.section8-content-holder,
.section9-content-holder,
.section10-content-holder,
.section11-content-holder,
.section12-content-holder,
.section-newsletter-signup-content-holder {
    padding: 54px 0;
}
/*
    for the first section
 */
.section1-content-holder {
    background-color: #e8f2ed;
}
.section1-content-holder-container,
.section1-2-content-holder-container,
.section1-3-content-holder-container,
.section2-content-holder-container,
.section3-content-holder-container,
.section4-content-holder-container,
.section5-content-holder-container,
.section6-content-holder-container,
.section7-content-holder-container,
.section8-content-holder-container,
.section9-content-holder-container,
.section10-content-holder-container,
.section11-content-holder-container,
.section12-content-holder-container,
.section-newsletter-signup-content-holder-container {
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    display: flex;
}
.section1-content-holder-left,
.section1-2-content-holder-left,
.section1-3-content-holder-left,
.section2-content-holder-left,
.section3-content-holder-left,
.section4-content-holder-left,
.section5-content-holder-left,
.section6-content-holder-left,
.section7-content-holder-left,
.section8-content-holder-left,
.section9-content-holder-left,
.section10-content-holder-left,
.section11-content-holder-left,
.section12-content-holder-left,
.section-newsletter-content-holder-left {
    flex: 1;
    width: 48.5%;
    display: grid;
    place-content: center;
    padding-bottom: 12px!important;
    margin-bottom: 25px!important;
    margin-right: 3%;
    font-size: 18px;
}

.section2-content-holder-left,
.section8-content-holder-left {
    display: block;
}
.section1-content-holder-left-title-line,
.section1-3-content-holder-left-title-line,
.section3-content-holder-left-title-line,
.section5-content-holder-left-title-line,
.section7-content-holder-left-title-line,
.section9-content-holder-left-title-line,
.section11-content-holder-left-title-line,
.section-newsletter-content-holder-left-title-line {
    font-weight: 500;
    font-size: 38px;
}
.section1-content-holder-left-content-line,
.section1-3-content-holder-left-content-line,
.section3-content-holder-left-content-line,
.section5-content-holder-left-content-line,
.section7-content-holder-left-content-line,
.section9-content-holder-left-content-line,
.section11-content-holder-left-content-line,
.section-newsletter-content-holder-left-content-line {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font-weight: lighter;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
}
.section1-content-holder-right,
.section1-3-content-holder-right,
.section3-content-holder-right,
.section4-content-holder-right,
.section5-content-holder-right,
.section6-content-holder-right,
.section7-content-holder-right,
.section8-content-holder-right,
.section9-content-holder-right,
.section10-content-holder-right,
.section11-content-holder-right,
.section12-content-holder-right,
.section-newsletter-content-holder-right {
    flex: 1;
}
.section1-content-holder-right-content {

}
.section1-content-holder-right-content-img,
.section3-content-holder-right-content-img,
.section5-content-holder-right-content-img,
.section7-content-holder-right-content-img,
.section9-content-holder-right-content-img,
.section11-content-holder-right-content-img {
    width: 100%;
}


/*
    this is the Events section
 */
.section1-2-content-holder-left-content {
    display: flex;
}
.content-area-box-event-holder {
    flex: 1;
    position: relative;
    width: 157px;
    padding: 20px 20px 40px 20px;
    margin: 25px;
    border-radius: 5px;
    border: 1px solid #f09637;
}
.content-area-box-event-holder-title {
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 14px;
    white-space: initial;
    text-transform: capitalize;
}
.content-area-box-event-holder-title-year {
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 12px;
}
.content-area-box-event-holder-image-holder {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.content-area-box-event-holder-image {
    max-width: 150px;
    max-height: 150px;
    border-radius: 20px;
}
.content-area-box-event-footer-holder {
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 12px;
}
.content-area-box-event-footer-dates {
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 11px;
    margin: 0;
}
.content-area-box-event-footer-location {
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 11px;
    margin: 0;
    text-transform: capitalize;
}
.content-area-box-event-countdown-holder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font: var(--text-color);
    font-size: 13px;
}




/*
    this is the attendees section
 */
.section1-3-content-holder {
    background-color: #cbe0f7;
}
.section1-3-content-holder-left-content-footer {

}
.section1-3-content-holder-left-content-footer-label {
    color: #000000;
}
.section1-3-content-holder-right {

}
.section1-3-content-holder-right-content {
    display: grid;
    justify-content: center;
}

.event-attendees-list-item-holder-new {
    position: relative;
    display: flex;
    width: 287px;
    text-align: center;
    padding: 20px;
    margin: 20px 5px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #f09637;
}
.event-attendees-list-item-nickname-holder {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #f09637;
    border: 1px solid #428ce2;
}
.event-attendees-list-item-icon-holder {
    width: 50px;
}
.event-attendees-list-item-icon-holder-icon {
    max-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 15px auto 0 auto;
}
.event-attendees-list-item-center-content-holder {
    width: 100%;
    margin-left: 20px;
    text-align: left;
}
.event-attendees-list-item-center-content-name-holder {
    width: 90%;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.event-attendees-list-item-center-content-phone-holder {
    font-size: 14px;
    padding: 5px 0;
}
.event-attendees-list-item-center-content-third-line-holder {
    font-size: 14px;
    overflow: auto;
    margin-top: 10px;
}
.event-attendees-list-item-center-content-third-line-rsvp-title {
    width: 60%;
    float: left;
}
.event-attendees-list-item-center-content-third-line-rsvp-yes {
    font-size: 11px !important;
    color: #FFFFFF;
    background-color: #5E9F3D;
}
.event-attendees-list-item-center-content-third-line-rsvp-no-answer {
    font-size: 11px !important;
    color: #FFFFFF;
    background-color: #f09637;
}
.event-attendees-list-item-center-content-third-line-icons-holder {
    width: 40%;
    text-align: right;
    float: right;
}
.event-attendees-list-item-center-content-third-line-icons-host {
    margin-left: 10px;
}
.event-attendees-list-item-center-content-third-line-icons-notifications {
    margin-left: 10px;
}



/*
    for the where to go section
 */
.section2-content-holder {
    display: flex;
    padding: 54px 0;
}
.section2-content-holder-left-content {

}
.section1-2-content-holder-left-content-img,
.section2-content-holder-left-content-img,
.section4-content-holder-left-content-img,
.section6-content-holder-left-content-img,
.section8-content-holder-left-content-img,
.section10-content-holder-left-content-img {
    width: 100%;
}
.section1-2-content-holder-right,
.section2-content-holder-right,
.section4-content-holder-right,
.section6-content-holder-right,
.section8-content-holder-right,
.section10-content-holder-right,
.section12-content-holder-right {
    flex: 1;
    width: 48.5%;
    display: grid;
    place-content: center;
    padding-bottom: 12px!important;
    margin-bottom: 25px!important;
    margin-right: 3%;
    font-size: 18px;
}
.section1-2-content-holder-right-title-line,
.section2-content-holder-right-title-line,
.section4-content-holder-right-title-line,
.section6-content-holder-right-title-line,
.section8-content-holder-right-title-line,
.section10-content-holder-right-title-line,
.section12-content-holder-right-title-line {
    font-weight: 500;
    font-size: 38px;
}
.section1-2-content-holder-right-content-line,
.section2-content-holder-right-content-line,
.section4-content-holder-right-content-line,
.section6-content-holder-right-content-line,
.section8-content-holder-right-content-line,
.section10-content-holder-right-content-line,
.section12-content-holder-right-content-line {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font-weight: lighter;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
}
.section1-2-content-holder-right-content-footer,
.section2-content-holder-right-content-footer {

}
.section1-2-content-holder-right-content-footer-label,
.section2-content-holder-right-content-footer-label {
    color: #000000;
}

/*
    for the how to get there section
 */
.section3-content-holder {
    background-color: #e8f2ed;
    display: flex;
    padding: 54px 0;
}
.section3-content-holder-right-content-img {
    border-radius: 5px;
}
.section3-content-holder-left-content-footer {

}
.section3-content-holder-left-content-footer-label {
    color: #000000;
}

/*
    for the where to stay section
 */
.section4-content-holder {
    display: flex;
    padding: 54px 0;
}
.section4-content-holder-left {
    padding-bottom: 0 !important;
}
.section4-content-holder-left-content {
    width: 90%;
    margin: 0 auto;
}
.section4-content-holder-left-content-top-line {
    display: flex;
    margin: 0 auto 20px auto;
    justify-content: center;
}
.section4-content-holder-left-content-bottom-line {
    display: flex;
    justify-content: center;
}

.section4-content-holder-left-content-icon-holder {
    min-width: 100px;
    height: 100px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text-color);
    position: relative;
    padding: 5px;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid #f09637;
    background-color: #fcf9dd;
}
.section4-content-holder-left-content-icon-holder-icon {
    font-size: 36px !important;
}
.section4-content-holder-left-content-icon-holder-title {
    font-size: 12px;
}
.section4-content-holder-right-content-footer {

}
.section4-content-holder-right-content-footer-label {
    color: #000000;
}

/*
    for the what to eat section
 */
.section5-content-holder {
    background-color: #cbe0f7;
}
.section5-content-holder-container {
    display: block;
}
.section5-content-holder-title-line {
    width: 100%;
}
.section5-content-holder-title-line-title {
    font-weight: 500;
    font-size: 38px;
    text-align: center;
}
.section5-content-holder-title-line-content {
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
}
.section5-content-holder-title-line-footer {
    text-align: center;
}
.section5-content-holder-title-line-footer-label {
    color: #000000;
}

.section5-content-holder-content-line {
    display: flex;
    margin: 20px 0;
}
.section5-content-holder-content-line-item-holder {
    flex: 1;
}


.section5-content-holder-bottom-tray {
    max-width: 500px;
    position: relative;
    display: flex;
    margin: 0 auto;
    padding-bottom: 10px;
}
.section5-content-holder-bottom-tray-item-holder {
    flex: 1;
}


/*
    for the what to take section
 */
.section6-content-holder {

}
.section6-content-holder-left-content-item-holder {
    width: 360px;
}
.event-supplies-attendee-list-subcategory-holder {
    position: relative;
    padding: 20px 10px 10px 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    border: 1px solid #f09637;
    border-radius: 5px;
}
.event-supplies-attendee-list-item-category-line-category {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #f09637;
}
.event-supplies-attendee-list-subcategory-item-holder {
    position: relative;
    display: flex;
}
.event-supplies-attendee-list-subcategory-item-checkbox-status-holder {
    flex: 0 1 auto;
    padding-top: 10px;
}
.event-supplies-attendee-list-subcategory-item-center-content-holder {
    flex: 1 1 auto;
}
.event-supplies-attendee-list-item-name {
    font-size: 14px;
    padding: 10px 0 0 10px;
    text-transform: capitalize;
}
.event-supplies-attendee-list-subcategory-item-edit-icon-holder {
    flex: 0 0 35px;
    width: 25px;
}
.event-supplies-attendee-list-item-category-line-essential-icon {
    font-size: 10px;
    color: #FF1300;
}
.event-supplies-attendee-list-item-category-line-private-icon {
    font-size: 10px;
    margin-right: 5px;
    color: #5E9F3D;
}
.section6-content-holder-right-content-footer {

}
.section6-content-holder-right-content-footer-label {
    color: #000000;
}




/*
    for the what to do section
 */
.section7-content-holder {
    background-color: #e8f2ed;
}
.section7-content-holder-right-content {
    display: flex;
    padding-top: 20px;
}
.section7-content-holder-left-content-footer {

}
.section7-content-holder-left-content-footer-label {
    color: #000000;
}

/*
    for the reminders section
 */
.section8-content-holder {

}
.section8-content-holder-right-content-footer {

}
.section8-content-holder-right-content-footer-label {
    color: #000000;
}



/*
    for the messaging section
 */
.section9-content-holder {
    background-color: #cbe0f7;
}
.section9-content-holder-left-content-footer {

}
.section9-content-holder-left-content-footer-label {
    color: #000000;
}
.section9-content-holder-right-content{
    display: grid;
    justify-content: center;
}
.section9-content-holder-right-content-img {
    max-height: 400px;
    width: auto;
    border-radius: 5px;
}

/*
    for the notifications section
 */
.section10-content-holder {

}
.event-attendees-edit-item-holder-notifications-holder {
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}
.event-attendees-edit-item-holder-notifications-holder-label {
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #f09637;
    border: 1px solid #428ce2;
}
.event-attendees-edit-item-holder-notifications-description {
    font-size: 12px;
}
.event-notification-item-holder {
    display: flex;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}
.event-notification-item-holder-label-holder {
    width: 90%;
}
.event-notification-item-holder-label {
    font-size: 14px;
}
.event-notification-item-holder-checkbox {
    width: 10%;
}




/*
    for the photos section
 */
.section11-content-holder {
    background-color: #e8f2ed;
}
.section11-content-holder-right-content{
    display: grid;
    justify-content: center;
}
.section11-content-holder-right-content-img {
    max-height: 400px;
    width: auto;
    border-radius: 5px;
}


/*
    for the budget section
 */
.section12-content-holder {

}
.section12-content-holder-left-content {
    display: flex;
    flex-wrap: wrap;
}
.section12-content-holder-left-content-footer {

}
.section12-content-holder-left-content-footer-label {
    color: #000000;
}






.event-meals-item-day-event-holder {
    max-width: 340px;
    min-height: 219px;
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}

.event-card-event-meal-item-day-header-meal-name {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.event-card-event-meal-item-day-header-meal-time {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.event-card-event-meal-item-name-holder {
    font-size: 13px;
    margin-bottom: 10px;
}
.event-card-event-meal-item-location-holder {
    position: relative;
    text-align: left;
}
.event-card-event-meal-item-location-name-holder {
    font-size: 16px;
    padding-right: 40px;
    padding-bottom: 10px;
}
.event-card-event-meal-item-meal-address-holder {
    font-size: 12px;
}
.event-card-event-meal-item-meal-city-state-zip-holder {
    font-size: 12px;
    text-transform: capitalize;
}
.event-card-event-meal-item-location-copy {
    position: absolute !important;
    top: 0 !important;
    right: 0;
    padding: 5px;
    color: var(--text-color);
    background-color: var(--bg-color-offset);
    border-radius: 5px;
}
.event-card-event-meal-item-phone-holder {
    font-size: 12px;
    margin-top: 10px;
}

.event-card-event-meal-item-notes-holder {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--bg-color-offset);
    border: 1px solid #cbe0f7;
}
.event-card-event-meal-item-notes-holder-label {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 11px;
    color: var(--text-color) !important;
    background-color: var(--primary-third-color);
}
.event-card-event-meal-item-notes-holder-text {
    font-size: 12px;
    color: var(--text-color) !important;
}
.event-card-event-meal-item-details-holder {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    border-top: 1px solid #428ce2;
    border-bottom: 1px solid #428ce2;
    background-color: #428ce205;
}
.event-card-event-meal-item-details-holder-label {
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 11px;
    color: var(--text-color) !important;
    background-color: #FFFFFF;
}
.event-card-event-meal-item-details-holder-text {
    display: block;
    font-size: 12px;
    margin-left: 0;
    color: var(--text-color) !important;
}
.event-meals-add-meal-eatery-details-notes {
    font-size: 12px;
}
.event-meals-add-meal-eatery-details-eatin {
    font-size: 12px;
    text-align: right;
}
.event-card-event-meal-item-receipt-item-holder {
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.event-card-event-meal-item-receipt-item-icon {
    padding: 10px;
}

.event-meals-add-meal-names-item-holder {
    min-width: 75px;
    height: 75px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 5px;
    margin-right: 20px;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}
.event-meals-add-meal-names-item-holder-add-icon {
    position: absolute !important;
    top: -5px !important;
    right: -5px;
    color: var(--button-bg-submit-green);
    border: 1px solid #428ce2;
    border-radius: 20px;
    padding: 5px;
    background: #fcf9dd;
    font-size: 10px;
}
.event-meals-add-meal-names-item-holder-icon {
    font-size: 36px !important;
    color: #f09637;
}
.event-meals-add-meal-names-item-holder-title {
    font-size: 12px;
}





.event-ideas-list-items-list-item-holder {
    min-width: 240px;
    max-width: 275px;
    flex: 1;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}
.event-idea-details-title-holder {
    position: relative;
    font-size: 16px;
    padding-bottom: 20px;
    padding-left: 60px;
}
.event-idea-list-item-details-attendee-icon {
    position: absolute !important;
    top: -45px !important;
    left: -45px;
    max-width: 75px;
    max-height: 75px;
    border-radius: 50%;
    font-size: 36px;
    color: #FFFFFF;
    background-color: #000000;
    padding: 10px;
}
.event-idea-list-item-details-category-icon {
    position: absolute !important;
    top: 0 !important;
    left: 20px;
    font-size: 24px;
}
.event-idea-details-url-holder,
.event-idea-details-phone-holder,
.event-idea-details-address-holder {
    font-size: 14px;
    margin-bottom: 30px;
    overflow-wrap: anywhere;
}
.event-idea-details-url-holder,
.event-idea-details-phone-holder,
.event-idea-details-address-holder {
    font-size: 14px;
    margin-bottom: 30px;
    overflow-wrap: anywhere;
}
.event-idea-details-comments-window {
    position: relative;
    border-radius: 10px;
    border-top: 1px solid #428ce2;
    border-bottom: 1px solid #428ce2;
    background-color: #428ce205;
}
.event-idea-details-comments-label {
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 11px;
    color: var(--text-color) !important;
    background-color: #FFFFFF;
}
.event-idea-details-comments-holder {
    max-height: 300px;
    padding: 10px 0 0 0;
}
.event-idea-details-comment-item-holder {
    background-color: var(--bg-color-offset);
    padding: 10px;
    margin-bottom: 20px;
}
.event-idea-details-comment-item-text {
    font-size: 14px;
    padding: 0 0 10px 0;
}
.event-idea-details-comment-item-owner {
    font-size: 11px;
    float: left;
}
.event-idea-details-comment-item-timestamp {
    font-size: 11px;
    float: right;
}


.event-activities-item-day-event-holder {
    min-width: 175px;
    max-width: 240px;
    position: relative;
    margin: 0 20px 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}
.event-activities-item-day-event-holder-activities-icon {
    position: absolute !important;
    top: -25px !important;
    right: -25px;
    max-width: 75px;
    max-height: 75px;
    border-radius: 50%;
    font-size: 36px;
    color: #FFFFFF;
    background-color: #000000;
    padding: 10px;
}
.event-card-event-activity-item-day-header {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}
.event-card-event-activity-item-name-holder {
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 10px;
}
.event-card-event-activity-item-location-holder {
    position: relative;
    margin-right: 20px;
    text-align: left;
}
.event-card-event-activity-item-location-name-holder {
    font-size: 12px;
}
.event-card-event-activity-item-activity-address-holder {
    font-size: 12px;
}
.event-card-event-activity-item-activity-city-state-zip-holder {
    font-size: 12px;
}
.event-card-event-activity-item-phone-holder {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.event-card-event-activity-item-location-copy {
    position: absolute !important;
    top: 0 !important;
    right: -30px;
    padding: 10px;
    color: var(--text-color);
    background-color: var(--primary-third-color);
    border-radius: 5px;
}
.event-card-event-activity-item-attendee-list-holder {
    width: 100%;
    position: relative;
    text-align: right;
    padding: 10px 0 0 0;
    overflow: auto;
}
.event-card-event-activity-item-attendee-list-item-holder {
    position: relative;
    padding: 8px;
    display: grid;
}
.event-card-event-activity-item-attendee-list-item-icon {
    font-size: 24px;
    color: var(--new-orange);
}
.event-card-event-activity-item-attendee-list-item-count {
    position: absolute;
    top: 1px;
    right: 0;
    font-size: 12px;
}




.event-todos-list-items-list-item-holder {
    max-width: 360px;
    position: relative;
    min-height: 70px;
    padding: 10px;
    margin: 25px auto;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #e9967a;
    border-radius: 5px;
}
.event-todo-list-item-left-side-holder {
    position: relative;
    padding-bottom: 20px;
}
.event-todo-list-item-category-icon-holder {
    float: left;
}
.event-todo-list-item-category-icon {
    font-size: 24px !important;
}
.todo-priority-high-icon-color {
    color: orange;
}
.todo-priority-medium-icon-color {
    color: #00a3f6;
}
.todo-priority-urgent-icon-color {
    color: red;
}
.event-todo-list-item-right-side-time-holder {
    font-size: 14px;
    padding: 5px 20px 0 20px;
}
.event-todo-list-item-right-side-title-content-holder {
    padding: 10px 0 10px 10px;
}
.event-todo-list-item-right-side-title-holder {
    font-size: 14px;
    padding-right: 20px;
    padding-bottom: 10px;
    text-transform: capitalize;
}
.event-todo-list-item-right-side-notes-holder {
    font-size: 12px;
    padding-right: 20px;
    padding-left: 10px;
}
.event-todo-list-item-right-side-reminders-holder {
    padding: 10px 0 10px 10px;
    margin-top: 20px;
    background-color: var(--bg-color-offset);
}
.event-todo-list-item-right-side-reminder-icon {
    float: left;
}
.event-todo-list-item-right-side-reminders-title {
    font-size: 14px;
    padding-left: 30px;
}
.event-todo-list-item-right-side-reminders-days-before, .event-todo-list-item-right-side-reminders-day-of {
    font-size: 12px;
    padding-left: 30px;
}
.event-todo-list-item-right-side-bottom-tray-holder {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 20px;
}
.event-todo-list-item-right-side-bottom-tray-assignee-holder {
    font-size: 11px;
    float: left;
}
.event-todo-list-item-right-side-bottom-tray-checkbox-holder {
    float: right;
    font-size: 12px;
}
.event-todo-list-item-right-side-bottom-tray-checkbox {
    padding: 10px !important;
    margin: 0 10px;
    height: 15px;
    width: 15px;
    position: relative;
    top: 2px;
    float: right;
}



.event-receipts-category-item-holder {
    position: relative;
    min-width: 75px;
    padding: 10px;
    margin: 0 10px 20px 0;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #f09637;
}
.event-receipts-category-item-holder-count {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100px;
    padding: 1px 5px;
    font-size: 11px;
    color: var(--text-color);
}
.event-receipts-category-item-holder-icon {
    font-size: 36px !important;
    padding: 10px 0;
    color: #f09637;
}
.event-receipts-category-item-holder-title {
    font-size: 12px;
    padding: 10px 0;
}
.event-receipts-category-item-holder-totals {
    font-size: 14px;
}

/*
    for the RSVP
 */
.rsvp-area {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 60px;
}
.rsvp-event-name {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.rsvp-event-image-holder {
    text-align: center;
    margin: 20px 0;
}
.rsvp-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
}
.rsvp-message {

}
.rsvp-description {

}
.rsvp-display-area {

}


/*
    for the NEWSLETTER
 */
.section-newsletter-signup-content-holder {
    background-color: #fcfcfc;
}
.section-newsletter-content-holder-left {

}
.section-newsletter-content-holder-left-content-preferences {
    width: 360px;
    margin: 0 auto;
}
.section-newsletter-content-holder-left-content-preference-item-holder {
    display: flex;
    margin: 10px;
}
.section-newsletter-content-holder-left-content-preference-item-checkbox {
    flex: 0 0;
}
.section-newsletter-content-holder-left-content-preference-item-title {
    flex: 1 0;
    margin-left: 20px;
}
.section-newsletter-content-holder-right-title-line {
    margin-bottom: 40px;
    text-align: center;
}

.newsletter-signup-fname-feedback,
.newsletter-signup-email-address-feedback {
    position: absolute;
    top: 5px;
    right: -15px;
    z-index: 2;
    display: none;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    pointer-events: none;
}
.newsletter-signup-fname-feedback.show,
.newsletter-signup-email-address-feedback.show{
    display: block;
}
.newsletter-signup-opt-in-checkbox-line-holder {
    display: flex;
    margin: 0 20px 20px 20px;
}
.newsletter-signup-opt-in-checkbox-holder {
    padding: 10px;
}
.newsletter-signup-opt-in-checkbox-holder.error {
    border: 1px solid #ff0000;
}
.newsletter-signup-opt-in-checkbox {
    flex: 0 0;
}
.newsletter-signup-opt-in-title {
    flex: 1 0;
    margin-left: 20px;
}
.newsletter-signup-submit-button-holder {
    text-align: right;
    overflow: auto;
}
.newsletter-signup-submit-button {
    display: flex;
    float: right;
    padding: 10px 40px 10px 40px;
    color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 2px 4px var(--primary-third-color);
    border: none;
    background-color: green;
}
.newsletter-signup-submit-button-icon-check {
    font-size: 11px;
    color: #FFFFFF;
    display: none;
    margin-left: 10px;
}
.newsletter-signup-submit-button-icon-check.show {
    display: block;
}
.newsletter-signup-submit-results-holder {
    clear: both;
    margin: 20px 40px 10px 40px;
}


/*
    this is for the confirmEmailAddress.html page
    and the newsletterPreferences.html page
    and the unsubscribe.html page
 */
.newsletter-confirm-email-address-area,
.newsletter-preferences-area,
.newsletter-unsubscribe-area {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 60px;
}
.newsletter-confirm-email-address-title,
.newsletter-preferences-title,
.newsletter-unsubscribe-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
}
.newsletter-confirm-email-address-message,
.newsletter-preferences-message,
.newsletter-unsubscribe-message {

}
.newsletter-confirm-email-address-description,
.newsletter-preferences-description,
.newsletter-unsubscribe-description {

}
.newsletter-preferences-update-area {
    display: none;
    width: 360px;
    margin: 0 auto;
}
.newsletter-preferences-update-area.show {
    display: block;
}
.newsletter-preferences-update-area-item-holder {
    display: flex;
    margin: 10px;
}
.newsletter-preferences-update-area-item-checkbox {
    flex: 0 0;
}
.newsletter-preferences-update-area-item-title {
    flex: 1 0;
    margin-left: 20px;
}
.newsletter-preferences-update-area-submit-button-holder {
    text-align: right;
    overflow: auto;
}
.newsletter-preferences-update-area-submit-button {
    display: flex;
    float: right;
    padding: 10px 40px 10px 40px;
    color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 2px 4px var(--primary-third-color);
    border: none;
    background-color: green;
}
.newsletter-preferences-update-area-submit-button-icon-check {
    font-size: 11px;
    color: #FFFFFF;
    display: none;
    margin-left: 10px;
}
.newsletter-preferences-update-area-submit-results-holder {
    clear: both;
    margin: 20px 40px 10px 40px;
}





.label {
    display: inline-block;
    padding: 2px 5px 1px 5px;
    line-height: 1.5384616;
    border: 1px solid #428ce2;
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 0.1px;
    border-radius: 5px;
    margin: 0 2px 5px 0;
}





.footer-parent {
    margin-top: auto;
    width: 100%;
    height: 77px; /* Set the height of the footer as required */
    background-color: #081361;
    color: #fff;
    text-align: center;
    padding-top: 40px; /* Add some top padding for content within the footer */

}

.copyright {
    bottom: 0;
    font-size: 10px;
    margin: 20px;
}

.footer-right-side {
    width: 40%;
    margin: 0 auto;
    float: right;
}

.footer-links a {
    text-decoration: none;
    font-size: 14px;
    color: #FFFFFF;
    margin: 18px;
    font-weight: lighter;
    float: right;
}
