
.dashboard-container {

}

.logo-holder {
    position: relative;
}
.sideMenuHolder {
    position: relative;
}
.event-container,
.attendee-container {
    display: flex !important;
    flex-wrap: wrap;
    height: 100vh;
    padding-top: 100px;
    text-align: center;
}

.event-item,
.attendee-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    min-width: 300px;
    max-width: 300px;
    box-sizing: border-box;
}

