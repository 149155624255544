
.content-area-contact {
    width: 100%;
    min-height: 100%;
    padding-top: 90px;
    background-color: #FFFFFF;
    overflow: auto;
}

.contact-page {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.contact-every-trip-section {

}
.every-trip-title-holder {
    margin-top: 100px;
}
.contact-page-content-area {
    text-align: center;
}
.contact-area-holder {
    width: 100%;
    border-left: 4px dotted orange;
}
.contact-area {
    width: 50%;
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    box-shadow: 0 5px 10px -6px #777;
}
#subject, #email, #message {
    margin-bottom: 30px;
    font-size: 12px;
    color: #593079;
    width: 100%;
}
#subject, #email {
    background-color: transparent;
}
#message {
    border: none;
    background-color: #F3F3F3;
}
.index-page-contact_us-email-feedback,
.index-page-contact_us-message-feedback {
    position: absolute;
    top: 5px;
    right: -15px;
    z-index: 2;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    pointer-events: none;
    display: none;
}
.index-page-contact_us-email-feedback.show,
.index-page-contact_us-message-feedback.show {
    display: block;
}
.index-page-contact-us-email-feedback-message-holder,
.index-page-contact-us-message-feedback-message-holder {
    text-align: left;
    padding-left: 20px;
}
.index-page-contact-us-email-feedback-message-text,
.index-page-contact-us-message-feedback-message-text{
    font-size: 14px;
    color: #FF0000;
}
.index-page-contact-us-submit-results {
    font-size: 18px;
}
.contact-us-submit-button {
    width: 219px;
    height: 40px;
    background-color: #e8f2ed;
    border: 2px solid #081361;
    color: #081361;
    border-radius: 10px;
    margin-top: 50px;
    transition: all 0.5s ease-in-out;
}
.contact-us-submit-button:hover {
    background-color: #081361;
    border: 2px solid #081361;
    color: #e8f2ed;
}
@media only screen and (min-width : 150px) and (max-width : 769px) {
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0;
        padding: 10px;
        text-align: left;
        background-color: #cbe0f7;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items > ul > li > a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0;
    }
    .header-facebook-icon img {
        margin-right: 0;
    }
    .contact-page {
        width: 75%;
    }
    .contact-area {
        width: 90%;
    }
}
@media only screen and (min-width : 770px) and (max-width : 1500px) {
    .contact-area {
        width: 90%;
    }
}
