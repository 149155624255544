
.event-budget-dialog-box-content-holder {

}
.event-budget-dialog-box-content-total-budget-amount-holder {
    position: relative;
}
.event-budget-dialog-box-content-total-budget-amount-row {
    display: flex;
}
.event-budget-dialog-box-content-total-budget-amount-total,
.event-budget-dialog-box-content-total-budget-amount-actual,
.event-budget-dialog-box-content-total-budget-amount-available {
    position: relative;
    flex: 1;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.event-budget-dialog-box-content-total-budget-amount-total {
    cursor: pointer;
}
.event-budget-dialog-box-content-total-budget-icon {
    font-size: 30px;
    padding: 0 5px 15px 5px;
    color: #f09637;
}
.event-budget-dialog-box-content-category-summary-holder {
    display: flex;
}
.event-budget-dialog-box-content-category-icon-button {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6) !important;
}
.event-budget-dialog-box-content-category-icon {
    font-size: 30px;
    padding: 0 5px 15px 5px;
    color: #f09637;
}
.event-budget-dialog-box-content-total-budget-change-holder {
    padding: 30px 0;
    text-align: right;
}
