.todo-list-container {
    width: 350px;
}
.todo-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.todo-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.todo-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.todo-list-items-container{
     width: 320px;
     padding-top: 70px;
     margin-left: auto;
     margin-right: auto;
 }
.todo-suggestions-list-items-container {
    width: 350px;
    padding-top: 120px;
}
.todo-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.todo-list-attendee-list-container {
     padding-top: 50px;
     width: 350px;
     overflow-x: auto;
     white-space: nowrap;
     padding-right: 16px;
 }
.todo-list-items-container {
    padding-top: 50px;
    width: 350px;
}
.todo-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
