.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport */
}

.main-content {
    flex: 1; /* Make the main content take up the available space above the anchored top row */
    display: flex;
    overflow: hidden;
}

.column1 {
    width: 350px;
    background-color: lightblue; /* Background color for column 1 */
    padding: 10px;
    overflow: auto;
}

.column2 {
    width: 350px;
    background-color: lightgreen; /* Background color for column 2 */
    padding: 10px;
    overflow: auto;
}

.column3 {
    flex: 1;
    background-color: lightyellow; /* Background color for column 3 (resizable) */
    padding: 10px;
    overflow: auto;
}

.column4 {
    width: 300px;
    background-color: lightcoral; /* Background color for column 4 */
    padding: 10px;
    overflow: auto;
    transition: width 0.3s ease;
}

.column4.collapsed {
    width: 50px;
}

/* Mobile specific styling */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        overflow-y: auto;
    }

    /* Mobile menu content container */
    .mobile-menu-content-container {
        flex: 1;
        overflow-y: auto;
        background-color: lightgray; /* Background color for mobile content */
    }

    /* Anchored top row at the bottom in mobile */
    .mobile-bottom-row {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly; /* Evenly space out the icons */
        color: #151269;
        background-color: #cbe0f7;
        padding: 10px 0;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000; /* Ensure the top row stays on top */
    }

    .mobile-menu-item {
        color: #151269;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        cursor: pointer;
    }
    .mobile-menu-item i {
        font-size: 24px; /* Adjust the size of the icon */
        margin-bottom: 5px; /* Add spacing between icon and text */
    }

    /* Hide other columns in mobile view */
    .column1, .column2, .column4 {
        display: none;
    }

    .column3 {
        flex: 1;
        background-color: lightyellow;
    }
}
