
.supplies-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.supplies-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.supplies-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.supplies-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.supplies-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.supplies-list-attendee-list-container {
    max-width: 350px;
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}
.supplies-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}
.supplies-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}


.supplies-list-item-add-count {
    position: absolute;
    top:0;
    left: 0;
}
.supplies-list-item-add-count-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}
.supplies-list-item-add-count-disabled-chip {
    background-color: #f0f0f0;
    color: #999;
    pointer-events: none;
    margin-left: 10px !important;
}


@media (max-width: 500px) {
    .supplies-list-container {
        width: 320px;
    }
    .supplies-list-header {
        position: absolute;
        width: 320px;
    }
    .supplies-list-items-container {
        width: 320px;
    }
}
