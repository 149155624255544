.itinerary-list-container,
.itinerary-section-container {
    width: 350px;
}
.itinerary-list-header,
.itinerary-section-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.itinerary-list-header-text,
.itinerary-section-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.itinerary-list-header-chip-holder,
.itinerary-section-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.itinerary-list-items-container,
.itinerary-section-items-container {
    width: 320px;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
}
.itinerary-section-list-items-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 380px);
    overflow: auto;
}
.itinerary-section-container {

}
.itinerary-section-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.itinerary-section-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.itinerary-section-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

@media (max-width: 500px) {
    .itinerary-section-container {
        width: 320px;
    }
    .itinerary-section-header {
        position: absolute;
        width: 320px;
    }
    .itinerary-section-items-container {
        width: 320px;
    }
}