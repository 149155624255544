.app {
  display: flex;
  flex-direction: column;
}
.app-container {
  max-height: 90vh; /* Set the maximum height of the container to 90% of the viewport height */
  overflow-y: auto; /* Add vertical scrolling if the content exceeds the container's height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.app-header-holder {
  position: relative;
  top: 0;
}
.app-routes-holder {
  overflow-y: auto;
}
