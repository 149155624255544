.content-area-pricing {
    width: 100%;
    min-height: 100%;
    padding-top: 90px;
    background-color: #FFFFFF;
    overflow: auto;
}

.examples-pricing-page {
    width: 100%;
    margin-top: 50px;
}
.every-trip-section {
    background-color: #cbe0f7;
    height: 400px;
}
.every-trip-title {
    font-size: 30px;
    color: #081361;
    text-align: center;
    padding-top: 25px;
}
.trip-examples-row {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.trip-examples-column,
.trip-examples-column3 {
    width: 35%;
    text-align: center;
    margin-top: 65px;
    border-left: 4px dotted orange;
}
.trip-examples-column3 {
    border-right: 4px dotted orange;
}
.trip-examples-title {
    font-size: 25px;
    color: #10318f;
}
.trip-examples-description {
    width: 300px;
    color: #10318f;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    font-weight: lighter;
}
.pricing-section {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.pricing-title-holder {
    width: 725px;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    margin-top: 150px;
}
.pricing-title {
    color: #081361;
}
.pricing-row {
    display: flex;
    width: 625px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.pricing-separator-line {
    border-top: 3px dotted orange;
    border-width: thick;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.pricing-column {
    display: flex;
}
.pricing-column-plan1,
.pricing-column-plan2 {
    width: 300px;
    height: 300px;
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    box-shadow: 0 5px 10px -6px #777;
}
.pricing-column-plan1 {
    margin-right: 30px;
}
.pricing-plan-title {
    font-size: 24px;
    text-align: center;
    color: #10318f;
    margin-top: 10px;
}
.pricing-plan-subtitle {
    font-size: 18px;
    text-align: center;
    color: #f09637;
}
.pricing-plan-dollar-amount-free,
.pricing-plan-dollar-amount {
    text-align: center;
    font-size: 36px;
    color: #10318f;
}
.pricing-plan-dollar-amount-free {
    margin-top: 55px;
}
.pricing-plan-dollar-amount {
    margin-top: 30px;
}
.pricing-plan-dollar-amount-frequency {
    text-align: center;
    color: #10318f;
}
.pricing-top-column-preferred{
    position: absolute;
    top: -12px;
    left: 10px;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #f09637;
    border: 1px solid #428ce2;
}
.pricing-get-started-holder,
.pricing-get-started-holder-free {
    margin-left: auto;
    margin-right: auto;
}
.pricing-get-started-holder-free {
    width: 150px;
    margin-top: 80px;
}
.pricing-get-started-holder {
    width: 200px;
    margin-top: 60px;
}
.pricing-get-started-button,
.pricing-get-started-button-free {
    height: 40px;
    border: 2px solid #081361;
    background-color: #ffffff;
    color: #081361;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    font-size: 18px;
}
.pricing-get-started-button {
    width: 200px;
}
.pricing-get-started-button-free {
    width: 150px;
}
.pricing-get-started-button:hover,
.pricing-get-started-button-free:hover {
    background-color: #081361;
    border: 2px solid #081361;
    color: #ffffff;
}
.features-table {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}
.features-table-header {
    font-size: 40px;
    color: #081359;
    margin-top: 150px;
    text-align: center;
    margin-bottom: 50px;
}
.features-table-title {
    border:1px solid #FAF9F6;
    padding:5px;
    background: #FAF9F6;
    color: #313030;
    font-size: 16px;
}
.features-free {
    border: 2px solid #4682B4;
    text-align: center;
}
.features-per-event {
    border: 2px solid #abd154;
    text-align: center;
}
.features-monthly {
    border: 2px solid #f09637;
    text-align: center;
}
.features-yearly {
    border: 2px solid #D2042D;
    text-align: center;
}
table th {
    border:1px solid #FAF9F6;
    padding:5px;
    color: #313030;
}
table td {
    border:1px solid #FAF9F6;
    text-align:center;
    padding:5px;
    background: #ffffff;
    color: #313030;
}

/*----------------------------------------------*/
@media only screen and (min-width : 150px) and (max-width : 550px) {
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0px;
        padding: 10px;
        text-align: left;
        background-color: #e8f2ed;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items>ul>li>a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0px;
    }
    .header-facebook-icon img {
        margin-right: 0px;
    }
    .every-trip-section {
        height: 500px;
    }
    .trip-examples-row {
        height: 300px;
        width: 95%;
    }
    .trip-examples-column,
    .trip-examples-column3 {
        width: 33%;
    }
    .trip-examples-title {
        margin: 5px;
        font-size: 22px;
    }
    .trip-examples-description {
        width: 95%;
    }
    .pricing-section {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
    }
    .pricing-title-holder {
        width: 95%;
        text-align: center;
        margin-top: 150px;
    }
    .pricing-column {
        display: block;
    }
    .pricing-column-plan1,
    .pricing-column-plan2 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }
    .pricing-separator-line {
        width: 300px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .pricing-row {
        margin-top: 0px;
    }
    .features-table-header {
        width: auto;
    }
    .features-table {
        width: 90%;
    }
    .footer-right-side {
        width: auto;
        margin-right: 5px;
        display: flex;
        margin-top: 10px;
    }
    .copyright {
        margin-top: 10px;
        margin-left: 5px;
        width: 125px;
    }
    .footer-social-icons-holder {
        width: 175px;
    }
    .footer-instagram-icon {
        margin-left: 10px;
    }
}

/*----------------------------------------------*/
@media only screen and (min-width : 551px) and (max-width : 769px) {
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0px;
        padding: 10px;
        text-align: left;
        background-color: #e8f2ed;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items>ul>li>a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0px;
    }
    .header-facebook-icon img {
        margin-right: 0px;
    }
    .every-trip-section {
        height: 500px;
    }
    .trip-examples-row {
        height: 300px;
        width: 95%;
    }
    .trip-examples-column,
    .trip-examples-column3 {
        width: 33%;
    }
    .trip-examples-title {
        margin: 5px;
        font-size: 22px;
    }
    .trip-examples-description {
        width: 95%;
    }
    .pricing-section {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
    }
    .pricing-title-holder {
        width: 95%;
        text-align: center;
        margin-top: 150px;
    }
    .pricing-column {
        display: block;
    }
    .pricing-column-plan1,
    .pricing-column-plan2 {
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }
    .pricing-separator-line {
        width: 300px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .pricing-row {
        margin-top: 0px;
    }
    .features-table-header {
        width: auto;
    }
    .features-table {
        width: 90%;
    }
    .copyright {
        width: 125px;
    }
    .footer-right-side {
        width: 40%;
        float: right;
        margin-right: 10px;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width : 770px) and (max-width : 1099px) {
    .navbar {
        display: none;
    }
    .top-menu-login-button {
        width: auto;
    }
    #hamburger-icon {
        margin: auto 0;
        display: block;
        cursor: pointer;
    }
    #hamburger-icon div {
        width: 35px;
        height: 3px;
        background-color: #081361;
        margin: 6px 0;
        transition: 0.4s;
    }
    .mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #cbe0f7;
    }
    .mobile-menu-container {
        display: flex;
        width: auto;
        float: right;
        height: auto;
    }
    .mobile-menu-container-icon {
        padding: 10px;
        margin-top: 0;
        font-size: 30px;
        margin-left: 30px;
    }
    .mobile-menu-container-items {
        display: none;
    }
    .mobile-menu-container-items.visible {
        display: block;
    }
    .mobile-menu-container-items ul {
        position: absolute;
        top: 50px;
        right: 0px;
        padding: 10px;
        text-align: left;
        background-color: #e8f2ed;
        border-radius: 5px;
        z-index: 1;
        width: 200px;
    }
    .mobile-menu-container-items li {
        list-style-type: none;
        padding: 10px;
    }
    .mobile-menu-container-items>ul>li>a {
        text-decoration: none;
        color: #081361;
    }
    .header-social-icons-holder {
        display: none;
    }
    .socials-mobile-menu {
        display: inline-block;
    }
    .header-linkedin-icon img {
        margin-left: 0px;
    }
    .header-facebook-icon img {
        margin-right: 0px;
    }
    .every-trip-section {
        height: 450px;
    }
    .trip-examples-row {
        height: 250px;
        width: 95%;
    }
    .trip-examples-column,
    .trip-examples-column3 {
        width: 33%;
    }
    .trip-examples-title {
        margin: 5px;
        font-size: 22px;
    }
    .trip-examples-description {
        width: 95%;
    }
    .pricing-section {
        width: auto;
    }
    .features-table-header {
        width: auto;
    }
    .features-table {
        width: 70%;
    }
    .footer-right-side {
        width: 40%;
        float: right;
        margin-right: 10px;
    }
}
/*-----------Tablet-----------------------------------*/
@media only screen and (min-width: 1100px) and (max-width: 1299px) {
    .navbar {
        font-size: 14px;
    }
    .top-menu-items-holder {
        right: 0px;
    }
    .top-menu-signup-button {
        width: 100px;
    }
    .top-menu-login-button {
        width: 120px;
    }
    .trip-examples-description {
        margin-left: 10px;
        margin-right: 10px;
    }
    .pricing-section {
        width: auto;
    }
    .features-table {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .footer-right-side {
        width: 25%;
        margin-right: 0px;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width: 1300px) and (max-width: 1699px) {

    .features-table-header {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .features-table {
        width: 45%;
    }
    .footer-right-side {
        margin-right: 75px;
        width: 20%;
    }
}

/*----------------------------------------------*/
@media only screen and (min-width: 1700px) and (max-width: 2199px) {

    .features-table-header {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
    }
    .features-table {
        width: 40%;
    }
}
/*----------------------------------------------*/
@media only screen and (min-width: 2200px) and (max-width: 2500px) {
    .bottom-banner-holder-right {
        margin-left: 350px;
    }

}
