

.messages-list-container {
    width: 350px;
    height: calc(77vh);
}
.messages-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.messages-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.messages-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.messages-list-items-container{
    width: 320px;
    padding-top: 70px;
    margin-left: auto;
    margin-right: auto;
}