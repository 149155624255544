.photos-list-container {
    width: 350px;
}
.photos-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 2;
}
.photos-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.photos-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.photos-list-attendee-list-container {
    padding-top: 50px;
    max-width: 350px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}
.photos-list-items-container {
    height: calc(100vh - 360px);
    overflow: auto;
}
