.headerStyle {
    text-align: center;
    color: #fff;
    height: 64px;
    padding-inline: 50px;
    line-height: 64px;
    background-color: #7dbcea;
}
.content {
    text-align: center;
    min-height: 120px;
    line-height: 120px;
    color: #fff;
    background-color: #108ee9;
}
.home-div {
    position: relative;
    margin-top: 90px;
}
.home-background-container {
    position: relative;
    height: 100vh;
    width: 100vw;
}
@media (max-width: 768px) {
    .home-background-container {
        background-position: left center; /* Adjust this to move the image */
    }
}

.home-grid {
    position: relative;
    align-items: center;
    justify-content: center;
}
.home-grid-centering {
    align-items: center;
    justify-content: center;
    padding: 50px !important;
}
.home-grid-top-content {
    border-radius: 10px;
    padding: 10px;
    backdrop-filter: blur(6px);
    background-color: rgba(249, 250, 251, 0.6);
}

.home-box-background {
    backdrop-filter: blur(6px);
    background-color: rgba(249, 250, 251, 0.6);
}
.home-grid-centering-inputbox {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 !important;
    padding: 10px 0;
    text-align: center;
    backdrop-filter: blur(6px);
    background-color: rgba(249, 250, 251, 0.6);
}
.home-grid-centering-inputbox-grid{

}
.home-grid-centering-inputbox-grid-formcontrol {
    width: 300px;
}

