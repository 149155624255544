



.flights-list-container {
    width: 350px;
}
.flights-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.flights-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.flights-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.flights-list-attendee-list-container {
    max-width: 350px;
    padding: 50px 10px 0 10px;
    display: flex;
    overflow: auto;
    scroll-padding-right: 20px;
    white-space: nowrap;
}
.flights-list-items-container{
    padding: 10px;
    height: calc(100vh - 360px);
    overflow: auto;
}