/*
    for the top area location and dates
 */

.planning-location-dates-container {
    display: flex;
    width: 100%;
}
.planning-location-dates-holder {
    flex: 1;
    justify-content: center;
}
.planning-location-dates-location-holder {

}
.planning-location-dates-marker {
    margin-right: 10px;
    font-size: 24px;
}
.planning-location-dates-location-input-holder {

}

/*
    for the features list
 */
.planning-features-list-title {
    position: absolute;
    top: -9px;
    left: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #10318f;
    border: 1px solid #428ce2;
    padding: 2px;
    border-radius: 5px;
}
.planning-features-list-item-selected {
    background-color: #f09637;
}
.planning-features-list-item-not-selected {
    background-color: transparent;
}

.planning-days-suggestions-library-holder {
    display: flex;
    width: 100%;
}


/*
    for the days area
 */
.planning-days-list-holder {

}
.planning-days-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}


/*
    for the google map area
 */

.planning-map-area-search-inputbox-holder {
    position: absolute;
    top: 17px;
    right: 8px;
    z-index: 10;
}
.planning-map-area-layers-icon-holder {
    position: absolute;
    top: 70px;
    right: 8px;
    z-index: 10;
}
.planning-map-area-google-map-holder {

}
.planning-map-area-place-info-container {
    position: absolute;
    bottom: 17px;
    left: 20px;
    z-index: 10;
    width: 100%;
    max-width: 400px;
}
.planning-map-area-place-info-holder {
    position: relative;
    width: 100%;
}
.planning-map-area-place-info-name {
    font-size: 14px;
}
.planning-map-area-place-info-button-holder {
    position: absolute;
    top: -25px;
    right: -20px;
}
.planning-map-area-place-info-address {
    padding: 5px 0;
    font-size: 11px;
}
.planning-map-area-place-info-rating-holder {
    display: flex;
    padding: 5px 0;
}
.planning-map-area-place-info-type-holder {
    display: flex;
    padding: 5px 0;
    overflow: auto;
}
.planning-map-area-place-info-website-holder {
    padding: 5px 0;
    font-size: 12px;
    cursor: pointer;
}

.planning-google-map-marker-pricing-one-star {
    background-color: #00522a;
}
.planning-google-map-marker-pricing-two-star {
    background-color: #008c3a;
}
.planning-google-map-marker-pricing-three-star {
    background-color: #00bf49;
}
.planning-google-map-marker-pricing-four-star {
    background-color: #4edb73;
}
.planning-google-map-marker-pricing-five-star {
    background-color: #a1f7a4;
    color: #000000 !important;
}
.price-tag,
.planning-google-map-marker-layer-item {
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 8px;
    position: relative;
}

.price-tag::after,
.planning-google-map-marker-layer-item::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #2c47fa;
}
.planning-google-map-marker-layer-item {
    position: relative;
    background-color: #f09637;
    z-index: 10;
}
.planning-google-map-marker-layer-item-icon {

}
.planning-google-map-marker-layer-item-name {

}

.planning-google-map-marker-info-window-content {
    background-color: aliceblue;
    color: black;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
}

.search-results {
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 8px;
    position: relative;
}

.search-results::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #f09637;
}
.planning-google-map-marker-search-results-item {
    color:#000;
    background-color: #f09637;
}


/*
    for the suggestions area
 */
.planning-suggestions-list-holder {
    flex: 1;
    margin-bottom: 20px;
    position: relative;
}
.planning-suggestions-list-title {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 11px;
    color: #FFFFFF;
    background-color: #f09637;
    border: 1px solid #428ce2;
    padding: 2px;
}
.planning-suggestions-list {
    display: flex;
    flex-wrap: nowrap; /* Prevent Chips from wrapping to the next line */
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure the container takes up the available width */
}
@media (max-width: 1200px) {
    /* Apply styles only when the screen width is 1200px or wider */
    .planning-suggestions-list {
        justify-content: space-between; /* Evenly space items */
    }
}


/*
    for the library area
 */
.planning-library-list-holder {

}
.planning-library-list {
    cursor: pointer;
}

/*
    for the right side Concierge area
 */

.planning-concierge-holder {
    position: relative;
}
.planning-concierge-header-holder {
    position: absolute;
    display: flex;
    width: 300px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 1;
}
.planning-concierge-header-holder-icon {
    display: flex;
    place-items: center;
    font-size: 30px;
    margin-left: 10px;
    color: #ff8502;
}
.planning-concierge-header-holder-hand-pointer {
    display: none;
    flex: 1;
    justify-content: flex-end;
    place-items: center;
    font-size: 20px;
}
.planning-concierge-suggestion-area-holder {

}
.planning-concierge-output-area-holder {
    padding: 50px 10px 10px;
}
.planning-concierge-output-area-content-text {
    display: inline;
    font-size: 12px;
    overflow-y: auto;
}
.planning-concierge-output-area-content-cursor {
    display: inline-block;
    width: 10px;
    height: 12px;
    margin-left: 2px;
    background-color: black;
    animation: blink 0.7s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

