
.weather-list-container {
    width: 350px;
}
.weather-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.weather-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.weather-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.weather-list-timetable-list-container {
    padding-top: 50px;
    width: 350px;
    display: flex;
    overflow-x: auto; /* Corrected property */
    padding-bottom: 10px; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    white-space: nowrap; /* Corrected property */
}