:root {
    letter-spacing: 0.8px;

    --new-dark-blue: #10318f;
    --new-light-blue: #428ce2;
    --new-green: #abd154;
    --new-orange: #f09637;
    --new-pastel-green: #e8f2ed;
    --new-pastel-blue: #cbe0f7;
    --new-pastel-orange: #fef4ec;
    --new-pastel-yellow: #fcf9dd;

    --primary-blue-color: #719bf5;
    --primary-purple-color: #b0a6fe;
    --primary-third-color: #fcfcfc;

    --primary-bg-light: #f9f9f9;


    --bg-color: #FFFFFF;
    --bg-color-overlay: rgba(255,255,255,.8);
    --bg-color-offset:  rgba(255,255,255,.3);

    --bg-color-navigation-list: #f3f3f3;
    --bg-color-navigation-list-overlay: rgba(243, 243, 243,.8);

    --text-color: #151269;
    --text-color-match: #FFFFFF;

    --scrollbar-color : #F1F1F1;
    --chart-grid-color: #DDDDDD;

    --rectangle-background: rgba(255,255,255, 0.95);

    --section-area-section-label: #593079;

    --public-menu-items-text-color: #593079;

    --primary-icon-link-color: #719bf5;
    --primary-icon-background-color: #719bf5;

    background-color: var(--bg-color);
    color: var(--text-color);

    --button-bg-submit-green: #5E9F3D;
    --button-bg-cancel-red: #FF1300;

    --bg-bottom-menu: #f5f5f5;

    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;

    --flight-item-highlights: #593179;

}
