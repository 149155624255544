@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.icon-spinner4 {
    animation: spin 1s linear infinite;
    display: inline-block;
}

