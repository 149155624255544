.content-area-signup {
    width: 100%;
    min-height: 100%;
    padding-top: 120px;
    background-color: #FFFFFF;
    overflow: auto;
}

.content-area-signup-content-holder {
    width: 100%;
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    border: 3px dotted #f09637;
    border-radius: 10px;
}
.content-area-signup-content-title {
    text-align: center;
    font-size: 16px !important;
    margin-bottom: 20px !important;
}
.signup-concierge-output-area-holder {
    position: relative;
    display: inline-block;
    margin: 20px 0;
    padding: 20px 30px;
    font-size: 14px;
    font-family: monospace;
}
.signup-concierge-output-area-content-text {
    display: inline;
}
.signup-concierge-output-area-content-cursor {
    display: inline-block;
    width: 10px;
    height: 12px;
    background-color: black;
    animation: blink 0.7s infinite;
}
.signup-concierge-input-area-content-holder {
    padding: 20px 30px;
    text-align: center;
}

