
.receipts-list-container {
    width: 350px;
}
.receipts-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.receipts-list-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.receipts-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.receipts-list-attendee-list-container {
    padding-top: 50px;
    width: 350px;
    overflow-x: auto;
    white-space: nowrap;
    padding-right: 16px;
}
.receipts-list-items-container{
    width: 320px;
    padding-top: 70px;
    margin-left: auto;
    margin-right: auto;
}
.receipts-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}