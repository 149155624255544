



.meals-list-container {
    position: relative;
    width: 350px;
    height: calc(100vh - 210px);
}
.meals-list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 3;
}
.meals-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.meals-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.meals-list-header-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}
.meals-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.meals-list-timetable-list-container {
    max-width: 350px;
    padding: 10px;
    display: flex;
    overflow: auto; /* Corrected property */
    scroll-padding-right: 20px; /* Corrected property */
    scrollbar-width: thin; /* For modern browsers, thin scrollbar */
    white-space: nowrap; /* Corrected property */
    z-index: 2;
}

.meals-list-items-container{
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}

.meals-list-item {
    position: relative;
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.meals-list-item-checkin-date {
    position: absolute;
    top: 0;
    left: 0;
}
.meals-list-item-checkout-date {
    position: absolute;
    bottom: 0;
    left: 0;
}
.meals-list-item-stay-dates-holder {
    display: flex;
    font-size: 12px;
}
.meals-list-item-stay-dates {
    flex: 1;
}
.meals-list-item-date-count {
    flex: 1;
    text-align: right;
}
.meals-list-item-meals-name {

}
.meals-list-item-meals-address {

}

.meals-suggestions-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.meals-list-item-header-chip-holder,
.meals-suggestions-list-item-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
    position: absolute;
    top: 0;
    right: -10px;
}
.meals-add-list-items-container,
.meals-suggestions-add-list-items-container {
    padding-top: 60px;
}
.meals-add-list-item,
.meals-suggestions-add-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}
.meals-suggestions-add-list-item-photos {
    max-height: 200px;
    padding: 0 10px;
}
.horizontal-scroll-container {
    display: flex; /* Align items in a row */
    max-height: 200px;
    overflow-x: auto; /* Allow horizontal scrolling */
    gap: 8px; /* Add spacing between images */
    padding: 8px 0; /* Optional padding for better spacing */
    scrollbar-width: thin; /* For modern browsers, thin scrollbar */
}

.horizontal-scroll-container::-webkit-scrollbar {
    height: 8px; /* Adjust scrollbar height */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
    background: #ccc; /* Style the scrollbar thumb */
    border-radius: 4px;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #888; /* Darker on hover */
}

.photo {
    max-width: 150px; /* Adjust image width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: subtle shadow */
    flex-shrink: 0; /* Prevent images from shrinking */
}

.meals-suggestions-add-list-item-footer-container {
    display: flex;
    width: 100%;
    height: 35px;
    text-align: center;
    align-items: center;
}
.meals-suggestions-add-list-item-footer-dates-container,
.meals-suggestions-add-list-item-footer-location-container,
.meals-suggestions-add-list-item-footer-attendees-container,
.meals-suggestions-add-list-item-footer-costs-container,
.meals-suggestions-add-list-item-footer-details-container {
    font-size: 25px;
}
.meals-suggestions-add-list-item-footer-dates-container {
    flex: 1;
    cursor:pointer;
}
.meals-suggestions-add-list-item-footer-date-holder {
    padding: 10px 0;
    font-size: 12px;
    text-align: right;
}
.meals-suggestions-add-list-item-footer-date,
.meals-suggestions-add-list-item-footer-time {
    padding: 5px 10px;
    align-items: center;
}
.meals-suggestions-add-list-item-footer-date-input,
.meals-suggestions-add-list-item-footer-time-input {

}

.meals-suggestions-add-list-item-footer-location-container {
    flex: 1;
    cursor:pointer;
}

.meals-suggestions-add-list-item-footer-attendees-container {
    flex: 1;
    cursor:pointer;
}
.meals-suggestions-add-list-item-footer-costs-container {
    flex: 1;
    cursor:pointer;
}
.meals-suggestions-add-list-item-footer-costs-holder {
    padding: 0 10px;
}
.meals-suggestions-add-list-item-footer-costs-tab-amount {
    font-size: 12px;
}
.meals-suggestions-add-list-item-footer-costs-header {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: right;
}
.meals-suggestions-add-list-item-footer-costs-total-holder {
    display: flex;
    margin-bottom: 20px;
}
.meals-suggestions-add-list-item-footer-costs-total {
    flex: 1;
    font-size: 14px;
    text-align: right;
    font-weight: 600;
}
.meals-suggestions-add-list-item-footer-costs-override-holder {

}
.meals-suggestions-add-list-item-footer-costs-override-input-holder{

}
.meals-suggestions-add-list-item-footer-cost-assignment-holder {
    margin-top: 10px;
}
.meals-suggestions-add-list-item-footer-cost-assignment-no-attendees {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.meals-suggestions-add-list-item-footer-cost-assignment-toggle-holder {
    text-align: right;
}
.meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder {
    font-size: 12px;
    margin-bottom: 10px;
}
.meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item {
    font-size: 12px;
    margin: 0 0 10px 0;
}
.meals-suggestions-add-list-item-footer-cost-assignment-occupant-cost {
    font-size: 12px;
}
.meals-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost {

}
.meals-suggestions-add-list-item-footer-cost-assignment-unassigned-holder {
    display: flex;
}
.meals-suggestions-add-list-item-footer-cost-assignment-unassigned-amount {
    font-size: 12px;
}

.meals-suggestions-add-list-item-footer-details-container {
    flex: 1;
    cursor:pointer;
}


@media (max-width: 768px) {
    .meals-list-container{
        height: calc(100vh - 150px);
    }
    .meals-list-items-container{
        height: calc(100vh - 276px);
    }
}