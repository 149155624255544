
.attendee-list-container {
    width: 350px;
    margin-bottom: 10px;
}
.attendee-list-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 1;
}
.attendee-list-header-text {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.attendee-list-header-info-icon-holder {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.attendee-list-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.attendee-list-items-container {
    padding-top: 50px;
    width: 350px;
    height: calc(100vh - 270px);
    overflow: auto;
}
.attendee-list-item {
    flex-grow: 1;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-sizing: border-box;
}

.attendee-list-item-rsvp-yes-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}
.attendee-list-item-rsvp-decline-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}
.attendee-list-item-rsvp-na-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}

.attendee-list-item-add-item-checkmark {
    display: block;
    font-weight: bold;
    color: green;
}
.attendee-list-item-add-item-no-checkmark {
    display: none;
}

.attendee-list-item-add-count {
    position: absolute;
    top:0;
    left: 0;
}
.attendee-list-item-add-count-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}
.attendee-list-item-add-count-disabled-chip {
    background-color: #f0f0f0;
    color: #999;
    pointer-events: none;
    margin-left: 10px !important;
}


@media (max-width: 500px) {
    .attendee-list-container {
        width: 320px;
    }
    .attendee-list-header {
        position: absolute;
        width: 320px;
    }
    .attendee-list-items-container {
        width: 320px;
    }
}
