

.dates-times-selection-container {
    width: 350px;
}
.dates-times-selection-header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 45px;
    backdrop-filter: blur(6px);
    background-color: rgba(198, 226, 255, 0.8);
    z-index: 1;
}
.dates-times-selection-header-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.dates-times-selection-header-chip-holder {
    display: flex;
    align-items: center;
    padding-right: 10px;
}
.dates-times-selection-list-items-container{
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 370px);
    overflow: auto;
}
.dates-times-selection-dates-container {
    padding-top: 70px;
    width: 350px;
}
.dates-times-selection-add-chip {
    background: linear-gradient(195deg, #e8f2ed, #abd154);
    border: 0;
    color: black;
    margin-left: 10px !important;
}
.dates-times-selection-times-text {
    font-size: 14px;
}


@media (max-width: 500px) {
    .dates-times-selection-container {
        width: 320px;
    }
    .dates-times-selection-header {
        position: absolute;
        width: 320px;
    }
    .dates-times-selection-dates-container {
        width: 320px;
    }
}
